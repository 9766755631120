import MediaPlayer_videoBoxComponent from '@wix/thunderbolt-elements/src/components/VideoBoxContainer/VideoBox/viewer/skinComps/VideoBox/videoBox.skin';


const MediaPlayer_videoBox = {
  component: MediaPlayer_videoBoxComponent
};


export const components = {
  ['MediaPlayer_videoBox']: MediaPlayer_videoBox
};


// temporary export
export const version = "1.0.0"
